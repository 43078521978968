<template lang="pug">
.language-chooser
  popper(:force-show="true" :options="{ placement: 'left' }" trigger="click" v-if="popoverVisible")
    .popper.new-feature-popper.brand-tooltip.brand-tooltip-light.brand-tooltip-left.mt-5
      translate-feature-popover(@close="closePopover")
    badge.new-feature-tooltip-badge(slot="reference" :text="$t('new')" type="rounded")
  .language-chooser-label {{ $t('translateFeature.templateLanguage') }}
  om-select#language-chooser.language-chooser-select(
    :options="languages"
    size="small"
    v-model="selectedLanguage"
  )
</template>

<script>
  import AVAILABLE_TEMPLATE_LANGUAGES from '@/graphql/AvailableTemplateLanguages.gql';
  import UPDATE_PREFERRED_TEMPLATE_LANGUAGE from '@/graphql/UpdatePreferredTemplateLanguage.gql';
  import Badge from '@/components/Badge.vue';
  import TranslateFeaturePopover from '@/components/Popovers/TranslateFeature.vue';
  import cookie from '@/mixins/cookie';

  export default {
    components: { Badge, TranslateFeaturePopover },
    mixins: [cookie],
    props: {
      preferredTemplateLanguage: { type: String, default: 'en' },
    },
    data: () => ({
      availableTemplateLanguages: {},
      popoverVisible: false,
      popoverClosedCookieKey: 'x-om-translate-feature-popover-closed',
    }),
    computed: {
      languages() {
        return Object.keys(this.availableTemplateLanguages).map((code) => ({
          value: this.$t(`translateFeature.languages.${code}`),
          key: code,
        }));
      },
      selectedLanguage: {
        get() {
          return { key: this.preferredTemplateLanguage };
        },
        async set(v) {
          this.selectedLanguage.key = v.key;
          this.$emit('change', v.key);
          await this.$apollo.mutate({
            mutation: UPDATE_PREFERRED_TEMPLATE_LANGUAGE,
            variables: {
              languageCode: v.key,
            },
          });
          window.location.reload();
        },
      },
    },
    apollo: {
      availableTemplateLanguages: {
        query: AVAILABLE_TEMPLATE_LANGUAGES,
      },
    },
    mounted() {
      if (!this.getCookie(this.popoverClosedCookieKey)) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.popoverVisible = true;
          }, 300);
        });
      }
    },
    methods: {
      closePopover() {
        this.popoverVisible = false;
        this.setPopoverClosed();
      },
      setPopoverClosed() {
        this.setCookie(this.popoverClosedCookieKey, 1);
      },
    },
  };
</script>

<style lang="sass">
  .language-chooser
    width: 360px
    display: flex
    justify-content: flex-end
    align-items: center
    &-label
      color: #505763
      font-size: 13px
      margin: 0 15px
    &-select
      width: 150px
</style>
