<template lang="pug">
transition(name="fade")
  .container.d-flex.flex-column.justify-content-start
    om-heading.text-center(
      h1
      :style="'padding: 50px 0 40px 0'"
      v-html="$t('onboarding.wizard.loading.title')"
    )
    lottie(:filename="`wizard_templates_loading_${getLocale}`" @loopComplete="handleEvent")
</template>
<script>
  import { mapGetters } from 'vuex';

  export default {
    computed: {
      ...mapGetters(['getLocale']),
    },
    created() {
      // preload loading animation
      import(`@/lottie/wizard_templates_loading_${this.getLocale}.json`);
    },
    methods: {
      handleEvent($event) {
        this.$emit('loaderComplete', $event);
      },
    },
  };
</script>
<style lang="sass">
  .modal-open .wizard-loader-modal .v--modal-box.v--modal
    top: 0!important
    height: 100vh
  .wizard-loader-modal
    .brand-modal .brand-modal-header,
    .brand-modal .brand-modal-body
      padding: 0!important
</style>
